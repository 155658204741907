<nav class="navbar navbar-expand-md navbar-light bg-light p-0 px-2">
  <a class="navbar-brand" href="#">
    <img alt="Zzapp Malaria" src="/assets/images/logo.svg" width="125"/>
  </a>

  <button [matMenuTriggerFor]="matMenu" color="primary" mat-button>
    {{ "header.menu" | translate }}
  </button>
  <mat-menu #matMenu="matMenu">
    <button [routerLink]="['']"
            mat-menu-item>
      {{ "header.map" | translate }}
    </button>
    <button [queryParams]="{ mode: 'managers' }"
            [routerLink]="['../main/users']"
            mat-menu-item>
      {{ "header.dashboard_users" | translate }}
    </button>
    <button [queryParams]="{ mode: 'users' }"
            [routerLink]="['../main/users']"
            mat-menu-item>
      {{ "header.worker_management" | translate }}
    </button>
    <button [matMenuTriggerFor]="reports"
            mat-menu-item>
      {{ "header.reports" | translate }}
    </button>
  </mat-menu>

  <mat-menu #reports="matMenu">
    <button [routerLink]="['../main/report_villages']" mat-menu-item>{{ "header.report_villages" | translate }}</button>
  </mat-menu>

  <ng-template #rt let-r="result" let-t="term">
    <div [ngStyle]="{'color': getSearchColor(r.isVillage)}">
      <ngb-highlight [result]="r.name" [term]="t" class="highlight"></ngb-highlight>
      @if (!r.isVillage) {
        <p class="ng-select-result">{{ r.location }}</p>
      }
    </div>
  </ng-template>

  @if (isSearchVisible) {
    <input #instance="ngbTypeahead"
           (click)="click$.next($any($event).target.value)"
           (focus)="focus$.next($any($event).target.value)"
           (selectItem)="onSearchSelected($event.item)"
           [(ngModel)]="model"
           [inputFormatter]="formatter"
           [ngbTypeahead]="search"
           [placeholder]="'header.search' | translate"
           [resultTemplate]="rt"
           class="form-control collapse navbar-collapse ms-2"
           type="text">
  }

  <ul class="navbar-nav ms-auto">
    <li class="nav-item m-2">
      <ng-select (change)="onCampaignSelected($event)"
                 [(ngModel)]="selectedCampaign"
                 [clearable]="false"
                 [items]="campaigns"
                 bindLabel="name"
                 bindValue="id"
                 [placeholder]="'header.select_campaign' | translate">
      </ng-select>
    </li>
  </ul>
  <button [matMenuTriggerFor]="matProfile"
          color="primary"
          mat-button>
    {{ 'header.profile' | translate }}
  </button>
  <mat-menu #matProfile="matMenu">
    @if (selectedCampaign) {
      <button [routerLink]="['campaigns', 'edit', selectedCampaign]"
              mat-menu-item>
        {{ "header.campaign_settings" | translate }}
      </button>
    }
    <button [routerLink]="['campaigns']"
            mat-menu-item>
      {{ "header.campaign_management" | translate }}
    </button>
    <mat-divider></mat-divider>
    <button (click)="onLogoutClicked()"
            mat-menu-item>
      {{ "header.logout" | translate }}
    </button>
  </mat-menu>
</nav>
